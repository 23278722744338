import React from 'react'

function B8() {
    return (
        <div>
            <h1>Sorry!</h1>
        </div>
    )
}

export default B8