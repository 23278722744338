import React from 'react'

function A5() {
    return (
        <div>
            <h1>Great</h1>
        </div>
    )
}

export default A5
