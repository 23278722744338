import React from 'react'

function B6() {
    return (
        <div>
            <h1>Then your balloon needs to be lightweight and small.  Something like a pico balloon would allow you to launch a weather balloon without recovering it....</h1>
        </div>
    )
}

export default B6