import React from 'react'

function A6() {
    return (
        <div>
            <h1>Then it is crucial you use a tool like https://predict.sondehub.org/  to calculate your flight's trajectory and avoid areas of heavy congestion or vegetation</h1>
        </div>
    )
}

export default A6
