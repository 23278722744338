import React from 'react'

function B3() {
    return (
        <div>
            <h1>You can actually use mylar party balloons in the case of a pico balloon, however you will have to stretch out the balloon.  I suggest you check out these links here</h1>
        </div>
    )
}

export default B3