import React from 'react'

function B1() {
    return (
        <div>
            <h1>Since you have a larger budget you can consider a variety of balloons such as the classic weather balloon or the pico baloon</h1>
        </div>
    )
}

export default B1
