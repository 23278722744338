import React from 'react'

function A10() {
    return (
        <div>
            <h1>Then you cannot use a satellite communicator like a SPOT to track you balloon, you will have to rely on alternate communication methods such as lora or ham radio</h1>
        </div>
    )
}

export default A10
