
import './Survey.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Survey() {
    const [answers, setAnswers] = useState(Array(10).fill(''));
    const navigate = useNavigate();
  
    const handleAnswerChange = (index, value) => {
      const newAnswers = [...answers];
      newAnswers[index] = value;
      setAnswers(newAnswers);
    };

    const getQuestionTitle = (index) => {
        // Customize titles based on the question index
        switch (index) {
        case 0:
            return 'Is your budget below $150?';
          case 1:
            return 'Do you live outside of the US?';
          case 2:
            return 'Are weather balloons avalible in your region?';
          case 4:
            return 'Do you live in the UK, Yemmen, Or North Korea?';
          case 5:
            return 'Have you launched a weather balloon before?';
           case 6:
            return 'Do you intend to recover your weather balloon?';
            case 7:
            return 'Is it diffucult to import international goods into your country?'; 
            case 8:
                return 'Are you near a party balloon store?';    
             case 9:
                 return 'Is it difficult contacting air regulatory authorities?';             
             case 10:
                    return 'Do you live in a country that banned satellite communicators?';        
          default:
            return `Question ${index + 1}: ${index % 2 === 0 ? 'Yes' : 'No'}`;
        }
      };
    
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Save answers to a state, local storage, or send to the server if needed
  
      // Redirect to the answer page
      navigate('/Answer', { state: { answers } }); // Pass answers as state
    };

    return (
        <div className="App">
        <header className="App-header">
        <div>
      <form onSubmit={handleSubmit}>
        {Array.from({ length: 10 }, (_, index) => (
          <div key={index} className="question-container">
            <h2>{getQuestionTitle(index)}</h2>
            <div>
              <label className={`option ${answers[index] === 'A' ? 'selected' : ''}`}>
                <input
                  type="radio"
                  name={`question_${index}`}
                  value="A"
                  checked={answers[index] === 'A'}
                  onChange={() => handleAnswerChange(index, 'A')}
                />
                Yes
              </label>
            </div>
            <div>
              <label className={`option ${answers[index] === 'B' ? 'selected' : ''}`}>
                <input
                  type="radio"
                  name={`question_${index}`}
                  value="B"
                  checked={answers[index] === 'B'}
                  onChange={() => handleAnswerChange(index, 'B')}
                />
                No
              </label>
            </div>
          </div>
        ))}
        <button type="submit">Submit</button>
      </form>
    </div>
        </header>
      </div>
    )
}

export default Survey
