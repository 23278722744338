import React from 'react'

function B5() {
    return (
        <div>
            <h1>Then you may want to consider starting small and then move your way up... 
                Here are a list of suggestions for small weather balloon projects ...
            </h1>
        </div>
    )
}

export default B5