import React from 'react'

function B9() {
    return (
        <div>
            <h1>Then I suggest that for large weather balloon flights you reach out to your local authorities.</h1>
        </div>
    )
}

export default B9