import React from 'react'

function A4() {
    return (
        <div>
            <h1>Then unfortunately you can not use Ham Radio Transmissions for your weather balloon and may need to use a different form of communication like satellite or LORA WAN</h1>
        </div>
    )
}

export default A4
