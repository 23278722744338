import React from 'react'

function B2() {
    return (
        <div>
            <h1>Great! You are in the US, be sure that your weather balloon is FAA 101 Exempt</h1>
        </div>
    )
}

export default B2