import React from 'react'

function A1() {
    return (
        <div>
            <h1>A low buget solution for a weather balloon can be a pico balloon... 
                You should consider checking out these resources <a href="https://groups.io/g/picoballoon">https://groups.io/g/picoballoon</a>
            </h1>
        </div>
    )
}

export default A1
