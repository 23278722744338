import './Answer.css';
import React from 'react';
import { useLocation } from 'react-router-dom';
import A1 from './A1';
import A2 from './A2';
import A3 from './A3';
import A4 from './A4';
import A5 from './A5';
import A6 from './A6';
import A7 from './A7';
import A8 from './A8';
import A9 from './A9';
import A10 from './A10';
import B1 from './B1';
import B2 from './B2';
import B3 from './B3';
import B4 from './B4';
import B5 from './B5';
import B6 from './B6';
import B7 from './B7';
import B8 from './B8';
import B9 from './B9';
import B10 from './B10';


const Answer = () => {
    const location = useLocation();
    const { state } = location;
    const answers = state && state.answers;
  
    const getComponentForAnswer = (answer, index) => {
      const componentIndex = answer === 'A' ? index + 1 : index + 11;
  
      // Use an object to map answers to components
      const componentsMap = {
        A: [A1, A2, A3, A4, A5, A6, A7, A8, A9, A10],
        B: [B1, B2, B3, B4, B5, B6, B7, B8, B9, B10],
      };
  
      // Check if the answer and index are within bounds
      if (componentsMap[answer] && componentsMap[answer][index]) {
        const Component = componentsMap[answer][index];
        return <Component key={index} />;
      }
  
      return null;
    };
  return (
    <div>
        <header className="App-header">
        <div>
      <ul>
        {answers &&
          answers.map((answer, index) => (
            <li key={index}>
              {`Question ${index + 1}: Selected option - ${answer}`}
              {getComponentForAnswer(answer, index)}
            </li>
          ))}
      </ul>
    </div>
    </header>
    </div>
  );
};

export default Answer;
