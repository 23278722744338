import React from 'react'

function A7() {
    return (
        <div>
            <h1>Then you may want to look into the resuse and refirbishment of Radiosondes.  This website can help you find out where your country's weather service launches their radiosondes: https://tracker.sondehub.org/</h1>
        </div>
    )
}

export default A7
