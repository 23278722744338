
import './App.css';

function App() {
  return (
<div>
<header className="App-header">
        <div>
     <div className="balloonLogo"></div>
     <a href='/Survey'><div className="balloonSurvey" >Take The Survey!</div></a>
    </div>
        </header>
</div>
  );
}

export default App;
